import * as http from "shared/http";
import { Paginated, ActiveThreatType } from "shared/types";
import useSWR from "swr";

export function getSkillsGoal(url: string) {
    return http.request<Paginated<ActiveThreatType>>({
        method: "get",
        url: url,
    });
}

export default function useSkillsGoal(id) {
    const { data, error } = useSWR(
        `/api/v1/user/trajectory_active/${id}/threats`,
        getSkillsGoal
    );

    return {
        skillList: data,
        isLoadingSkill: !error && !data,
        isErrorSkill: error,
    };
}
