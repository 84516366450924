import * as http from "shared/http";
import { Paginated, TrajectoryActiveType } from "shared/types";
import useSWR from "swr";

export function getGoal(url: string) {
    return http.request<Paginated<TrajectoryActiveType>>({
        method: "get",
        url: url,
    });
}

export default function useGoal(id: number) {
    const { data, error } = useSWR(`/api/v1/trajectory_active/${id}`, getGoal);

    return {
        dataGoal: data,
        isLoading: !error && !data,
        isError: error,
    };
}
