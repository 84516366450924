import * as http from "shared/http";
import { Paginated, TrajectoryActiveType } from "shared/types";
import useSWR from "swr";

export function getGoalsList() {
    return http.request<Paginated<TrajectoryActiveType>>({
        method: "get",
        url: `/api/v1/user/trajectory_actives`,
    });
}

export default function useGoalsList() {
    const { data, error } = useSWR("getGoalsList", getGoalsList);

    return {
        goalsList: data,
        isLoadingGoal: !error && !data,
        isErrorGoal: error,
    };
}
