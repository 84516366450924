import { ReactComponent as GirlDontKnow } from "shared/ui/icon/GirlDontKnow.svg";
import { getEvents, useStore, useStoreDate } from "./model";
import { useQuery } from "@tanstack/react-query";
import { Content } from "shared/types";
import { Loader } from "components/Loader/Loader";
import { useState } from "react";
import { current } from "immer";

function transformDate(date: Date) {
    const months = [
        "января",
        "февраля",
        "марта",
        "апреля",
        "мая",
        "июня",
        "июля",
        "августа",
        "сентября",
        "октября",
        "ноября",
        "декабря",
    ];

    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${day} ${months[monthIndex]} ${year} ${hours}:${minutes}`;
}

function EventItem({ content }: { content: Content }) {
    const [isExpanded, setIsExpanded] = useState(false);

    const date = new Date(content.event_date);
    const dateString = transformDate(date);

    return (
        <div
            data-isExpanded={isExpanded}
            className="group border-monochrome-light px-[20px] py-[10px] text-main-dark data-[isExpanded=true]:rounded-[6px] data-[isExpanded=false]:border-b data-[isExpanded=true]:shadow-darkBlue"
        >
            <div className="flex items-center">
                <div className="grow">
                    <p className="text-tr-m font-medium">{dateString}</p>
                    <p className="mt-[10px] text-tr-s font-medium">
                        {content.name}
                    </p>
                </div>
                <button
                    className="h-4 w-4"
                    onClick={() => setIsExpanded(!isExpanded)}
                >
                    {isExpanded ? "⬆" : "⬇"}
                </button>
            </div>
            {isExpanded && (
                <div className="mt-[10px] font-normal">
                    {content.url && (
                        <a className="underline" href={content.url}>
                            Ссылка на событие
                            <br />
                        </a>
                    )}
                    {content.description}
                </div>
            )}
        </div>
    );
}

export function Events() {
    const selectedDay = useStore((s) => s.selectedDay);

    const { currentMonth, currentYear } = useStoreDate();
    const { data, status, error } = useQuery({
        queryKey: ["events-list", currentYear, currentMonth],
        queryFn: () => getEvents(currentYear, currentMonth + 1),
    });

    const dateString = new Date(
        currentYear,
        currentMonth,
        selectedDay
    ).toLocaleString("ru", {
        year: "numeric",
        month: "long",
        day: "numeric",
    });

    const filteredEvents =
        data?.filter((event) => {
            const eventDate = new Date(event.event_date);
            return (
                currentMonth === eventDate.getMonth() &&
                selectedDay === eventDate.getDate()
            );
        }) || [];

    return (
        <div className="col-span-2 hidden w-full max-h-[665px] max-w-[825px] flex-col min-[800px]:flex">
            <p className="text-tr-m font-semibold text-[#264354] ">
                Все события
            </p>
            <p className="mt-[15px] text-tr-m font-normal text-[#264354]">
                {dateString}
            </p>

            <div className="overflow-y-auto">
                {status === "pending" || status === "error" ? (
                    <Loader text={"Загрузка..."} />
                ) : filteredEvents.length ? (
                    <ul className="space-y-[20px] py-[20px]">
                        {filteredEvents.map((content) => (
                            <EventItem content={content} key={content.id} />
                        ))}
                    </ul>
                ) : (
                    <div className="flex flex-col items-center">
                        <p className="text-tr-m font-medium text-main-dark">
                            Событий не запланировано
                        </p>
                        <GirlDontKnow className="ml-[20px] mt-[30px]" />
                    </div>
                )}
            </div>
        </div>
    );
}
