import { Calendar } from "components/Calendar/Calendar";
import { Events } from "components/Calendar/Events";
import { Loader } from "components/Loader/Loader";
import { useSessionPopup } from "components/MaterialSessionPopup/model";
import { ProgressBar } from "components/ProgressBar/ProgressBar";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useGoalsList from "shared/api/useGoalsList";
import { useIncomplete } from "shared/api/useIncomplete";
import useLeftSkillsGoal from "shared/api/useLeftSkillsGoal";
import useMaterialsSkill from "shared/api/useMaterialsSkill";
import useSkillsGoal from "shared/api/useSkillsGoal";
import useUser from "shared/api/useUser";
import useUserWeekProgress from "shared/api/useUserWeekProgress";
import { ActiveThreatType, TrajectoryActiveType } from "shared/types";
import ProgressCircle from "shared/ui/ProgressCircle";
import { ReactComponent as AchieveLoad } from "../../shared/ui/icon/achieveLoad.svg";
import { ReactComponent as EmptyAchievements } from "../../shared/ui/icon/EmptyAchievements.svg";
import { ReactComponent as ListClose } from "../../shared/ui/icon/ListClose.svg";
import { ReactComponent as ListOpen } from "../../shared/ui/icon/ListOpen.svg";
import "./index.css";

function ActiveInfoSkeleton() {
    return (
        <div className="flex h-full max-h-[665px] w-full flex-col items-center rounded-xl px-[30px] py-[25px] shadow-[0px_7px_22px_0px_#2643541A]">
            <p className="text-tr-m font-medium text-main-dark opacity-40">
                Ваш прогресс по целям
            </p>
            <div className="mt-[20px] flex w-full items-center justify-between">
                <div className="flex h-[35px] w-[35px] cursor-pointer items-center justify-center rounded-full">
                    <ListOpen className="rotate-90 opacity-30" />
                </div>
                <div className="flex h-[117px] w-[117px] items-center justify-center rounded-full border-[10px] border-[#D6D6D6] text-[#3F4A50]"></div>
                <div className="flex h-[35px] w-[35px] cursor-pointer items-center justify-center rounded-full">
                    <ListClose className="rotate-90 opacity-30" />
                </div>
            </div>

            <div className="mt-[10px] flex h-[8px] w-full max-w-[135px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]"></div>

            <div className="mt-[30px] flex w-full items-center justify-between ">
                <div className="flex h-[35px] w-[35px] cursor-pointer items-center justify-center rounded-full">
                    <ListOpen className="rotate-90 opacity-30" />
                </div>
                <div className="flex h-[80px] w-[80px] items-center justify-center rounded-full border-[8px] border-[#D6D6D6] text-[#3F4A50]"></div>
                <div className="flex h-[35px] w-[35px] cursor-pointer items-center justify-center rounded-full">
                    <ListClose className="rotate-90 opacity-30" />
                </div>
            </div>

            <div className="mt-[10px] flex h-[8px] w-full max-w-[135px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]"></div>

            <div className="mb-[130px] mt-[30px] flex w-full flex-col items-center gap-y-[10px]">
                <div className="flex w-full flex-col">
                    <div className="mt-[10px] flex h-[8px] w-full max-w-[95px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]"></div>
                    <div className="mt-[10px] flex h-[10px] w-full max-w-[285px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]"></div>
                </div>
                <div className="flex w-full flex-col">
                    <div className="mt-[10px] flex h-[8px] w-full max-w-[95px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]"></div>
                    <div className="mt-[10px] flex h-[10px] w-full max-w-[285px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]"></div>
                </div>
                <div className="flex w-full flex-col">
                    <div className="mt-[10px] flex h-[8px] w-full max-w-[95px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]"></div>
                    <div className="mt-[10px] flex h-[10px] w-full max-w-[285px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]"></div>
                </div>
                <div className="flex w-full flex-col">
                    <div className="mt-[10px] flex h-[8px] w-full max-w-[95px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]"></div>
                    <div className="mt-[10px] flex h-[10px] w-full max-w-[285px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]"></div>
                </div>
                <div className="flex w-full flex-col">
                    <div className="mt-[10px] flex h-[8px] w-full max-w-[95px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]"></div>
                    <div className="mt-[10px] flex h-[10px] w-full max-w-[285px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]"></div>
                </div>
            </div>
        </div>
    );
}

function MainGoalSkeleton() {
    return (
        <div className="mt-[20px] flex h-full w-full max-w-[1415px] gap-x-[25px]">
            <div className="flex h-full w-full max-w-[1415px] gap-x-[25px] ">
                <div
                    className={`z-50 flex h-full max-h-[665px] w-full max-w-[575px] flex-col rounded-xl bg-[#3F4A50] px-[20px] py-[25px]`}
                >
                    <div className="flex w-full items-start justify-between">
                        <div className="flex w-[375px] flex-col gap-y-[15px] text-tr-s font-medium text-white">
                            <p className="text-tr-s font-medium text-[#D6D6D6]">
                                Вы уже начали достигать цель
                            </p>
                            <div className="flex h-[20px] w-full max-w-[340px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]">
                                f
                            </div>
                        </div>
                        <div className="flex h-[144px] w-[144px] items-center justify-center rounded-full border-[10px] border-[#D6D6D6] text-[#3F4A50]">
                            f
                        </div>
                    </div>
                    <div className="mt-[25px] flex h-full flex-col gap-y-[20px]">
                        <div className="flex w-full max-w-[420px] flex-col gap-y-[40px]">
                            <div className="flex h-[16px] w-full max-w-[430px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]">
                                f
                            </div>
                            <div className="flex h-[16px] w-full max-w-[430px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]">
                                f
                            </div>
                            <div className="flex h-[16px] w-full max-w-[430px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]">
                                f
                            </div>
                            <div className="flex h-[16px] w-full max-w-[430px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]">
                                f
                            </div>
                            <div className="flex h-[16px] w-full max-w-[430px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]">
                                f
                            </div>
                            <div className="flex h-[16px] w-full max-w-[430px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]">
                                f
                            </div>
                            <div className="flex h-[16px] w-full max-w-[430px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]">
                                f
                            </div>
                        </div>
                    </div>
                    <div
                        className={` mt-[20px] flex w-full items-center justify-between `}
                    >
                        <div className="flex h-[16px] w-full max-w-[195px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]">
                            f
                        </div>
                        <div className="flex h-[55px] w-[55px] rotate-45 cursor-pointer items-center justify-center rounded-full bg-white text-[30px] text-[#264354B2] opacity-20">
                            &#8594;
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex h-full max-h-[665px] w-full max-w-[455px] flex-col gap-y-[20px] rounded-xl">
                <div className="flex h-full max-h-[190px] w-full max-w-[455px] flex-col rounded-xl p-[20px] shadow-[0px_7px_22px_0px_#2643541A]">
                    <div className="flex w-full justify-between opacity-40">
                        <p className="text-tr-s font-medium text-[#264354]">
                            Средняя эффективность
                        </p>
                        <div className="flex items-center gap-x-[10px]">
                            <p className="text-tr-s font-normal text-[#264354]">
                                Неделя
                            </p>
                            <ListOpen className="mb-[2px]" />
                        </div>
                    </div>
                    <div className="mt-[25px] flex items-end gap-x-[28px]">
                        <div className="flex flex-col items-center gap-y-[5px]">
                            <div className="h-[66px] w-[35px] rounded bg-[#D6D6D6]"></div>
                        </div>
                        <div className="flex flex-col items-center gap-y-[5px]">
                            <div className="h-[82px] w-[35px] rounded bg-[#D6D6D6]"></div>
                        </div>
                        <div className="flex flex-col items-center gap-y-[5px]">
                            <div className="h-[58px] w-[35px] rounded bg-[#D6D6D6]"></div>
                        </div>
                        <div className="flex flex-col items-center gap-y-[5px]">
                            <div className="h-[64px] w-[35px] rounded bg-[#D6D6D6]"></div>
                        </div>
                        <div className="flex flex-col items-center gap-y-[5px]">
                            <div className="h-[20px] w-[35px] rounded bg-[#D6D6D6]"></div>
                        </div>
                        <div className="flex flex-col items-center gap-y-[5px]">
                            <div className="h-[78px] w-[35px] rounded bg-[#D6D6D6]"></div>
                        </div>
                        <div className="flex flex-col items-center gap-y-[5px]">
                            <div className="h-[82px] w-[35px] rounded bg-[#D6D6D6]"></div>
                        </div>
                    </div>
                </div>

                <div className="flex h-full max-h-[455px] w-full max-w-[455px] flex-col rounded-xl p-[20px] shadow-[0px_7px_22px_0px_#2643541A]">
                    <p className="text-tr-s font-semibold text-main-dark opacity-40">
                        Ваши достижения
                    </p>
                    <div className="mt-[20px] grid grid-cols-2 gap-x-2 gap-y-2 overflow-y-auto">
                        <div className="group flex h-[145px] w-full cursor-pointer flex-col items-center justify-center gap-y-[20px] rounded border">
                            <AchieveLoad />
                            <div className="flex h-[6px] w-full max-w-[140px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]"></div>
                        </div>
                        <div className="group flex h-[145px] w-full cursor-pointer flex-col items-center justify-center gap-y-[20px] rounded border">
                            <AchieveLoad />
                            <div className="flex h-[6px] w-full max-w-[140px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]"></div>
                        </div>
                        <div className="group flex h-[145px] w-full cursor-pointer  flex-col items-center justify-center gap-y-[20px] rounded border">
                            <AchieveLoad />
                            <div className="flex h-[6px] w-full max-w-[140px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]"></div>
                        </div>
                        <div className="group flex h-[145px] w-full cursor-pointer flex-col items-center justify-center gap-y-[20px] rounded border">
                            <AchieveLoad />
                            <div className="flex h-[6px] w-full max-w-[140px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]"></div>
                        </div>
                    </div>
                    <div className="mt-[20px] flex w-full items-center justify-between">
                        <div className="flex h-[12px] w-full max-w-[195px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]"></div>
                        <div className="flex h-[55px] w-[55px] rotate-45 cursor-pointer items-center justify-center rounded-full bg-[#264354B2] text-[30px] text-white opacity-20">
                            &#8594;
                        </div>
                    </div>
                </div>
            </div>

            <ActiveInfoSkeleton />
        </div>
    );
}

function LeftSkill({ skill }) {
    const skillStat = (
        skill?.completed_content_count / skill?.content_count || 0
    ).toPrecision(1);

    const openActiveMaterial = useSessionPopup((state) => state.open);

    const handleClickLinkThreat = (threatId: number) => {
        if (typeof threatId !== "number") {
            throw new Error("invalid threatId");
        }
        openActiveMaterial(null, threatId, null);
    };

    return (
        <div className="flex w-full cursor-pointer justify-between">
            <p className="text-tr-m font-medium text-[#FFFFFF]">
                {skill?.threat.name}
            </p>
            <div className="flex gap-x-[5px]">
                <p className="text-[#369F48]">{Number(skillStat) * 100} %</p>
                <p
                    className="hidden -rotate-90 text-white group-hover:block"
                    onClick={() => handleClickLinkThreat(skill.id)}
                >
                    &#9660;
                </p>
            </div>
        </div>
    );
}

function DayProgress({ day, index }) {
    const daysOfWeek = ["ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ", "ВС"];

    const dayStat = (
        day.completed_materials / day.opened_materials || 0
    ).toPrecision(1);

    return (
        <div
            key={index}
            className="group flex cursor-pointer flex-col items-center justify-end gap-y-[3px]"
        >
            <div className="flex h-[18px] w-[35px] items-center justify-center rounded bg-[#264354] opacity-0 group-hover:opacity-100 ">
                <p className="text-tr-xs font-medium text-white">
                    {Number(dayStat) * 100}%
                </p>
            </div>
            <div
                className={`h-[${
                    Number(dayStat) * 100
                }px] w-[35px] rounded bg-[#8591AD] group-hover:bg-[#264354]`}
            ></div>
            <p className="text-tr-s font-medium text-[#264354]">
                {daysOfWeek[index]}
            </p>
        </div>
    );
}

function WeekProgress({ weekData }) {
    // Define an array of day names for easier mapping

    const weekData2 = [
        {
            day: "2024-09-02",
            opened_materials: 2,
            completed_materials: 1,
        },
        {
            day: "2024-09-03",
            opened_materials: 3,
            completed_materials: 1,
        },
        {
            day: "2024-09-04",
            opened_materials: 4,
            completed_materials: 1,
        },
        {
            day: "2024-09-05",
            opened_materials: 1,
            completed_materials: 1,
        },
        {
            day: "2024-09-06",
            opened_materials: 5,
            completed_materials: 1,
        },
        {
            day: "2024-09-07",
            opened_materials: 8,
            completed_materials: 2,
        },
        {
            day: "2024-09-08",
            opened_materials: 6,
            completed_materials: 4,
        },
    ];

    return (
        <div className="mt-[10px] flex h-[140px] items-end justify-between">
            {weekData.map((day, index) => (
                <DayProgress day={day} index={index} />
            ))}
        </div>
    );
}

function MainPage() {
    const { user, isLoading: isUserLoading, isError: isUserError } = useUser();
    // const { userData, isLoading, isError } = useUserInfo();
    const activeAchievementsList = useIncomplete();
    const { goalsList, isLoadingGoal, isErrorGoal } = useGoalsList();
    const activeGoalList = goalsList?.results.filter(
        (item) => item.content_count > 0
    );
    const [loadPage, setLoadPage] = useState(true);
    const [loadActiveInfo, setLoadActiveInfo] = useState(false);

    const navigate = useNavigate();
    const [openList, setOpenList] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const [active, setActive] = useState<TrajectoryActiveType>();
    const [threat, setThreat] = useState<ActiveThreatType>();
    const [leftActive, setLeftActive] = useState<TrajectoryActiveType>();
    const [openCard, setOpenCard] = useState(false);
    const LeftActiveGoalList = activeGoalList?.reduce((acc, curr) =>
        acc.completed_threats_count[0] / acc.threats_count[1] >
        curr.completed_threats_count[0] / curr.threats_count[1]
            ? curr
            : acc
    );

    const activeGoalList2 = activeGoalList?.filter(
        (m) => m.id !== LeftActiveGoalList.id
    );

    const { skillList, isLoadingSkill, isErrorSkill } = useSkillsGoal(
        active?.id
    );
    const activeSkillList = skillList?.results.filter(
        (item) => item.content_count > 0
    );

    const { materialsList, isLoadingMaterial } = useMaterialsSkill(threat?.id);
    const firstMaterial = materialsList?.results[0];

    const { leftSkillList } = useLeftSkillsGoal(leftActive?.id);
    const leftactiveSkillList = leftSkillList?.results.filter(
        (item) => item.content_count > 0
    );

    const [activeIndex, setActiveIndex] = useState(0);
    const [threatIndex, setThreatIndex] = useState(0);

    const { weekProgress } = useUserWeekProgress();

    const handleLinkGoal = (id) => {
        if (leftActive !== undefined) {
            console.log(active);
            navigate(`timeline/${id}`, { state: { leftActive } });
        }
    };

    const openActiveMaterial = useSessionPopup((state) => state.open);

    const handleClickLink = () => {
        if (typeof active.id !== "number") {
            throw new Error("invalid activeId");
        }
        openActiveMaterial(active.id, null, firstMaterial.id);
        // navigate(`/session/${item.id}`);
    };

    const handleClickLinkActive = (materialId: number) => {
        if (typeof active.id !== "number") {
            throw new Error("invalid activeId");
        }
        openActiveMaterial(active.id, null, materialId);
    };

    const handleClickLinkThreat = (threatId: number, materialId: number) => {
        if (typeof threatId !== "number") {
            throw new Error("invalid threatId");
        }
        openActiveMaterial(null, threatId, materialId);
    };

    useEffect(() => {
        window.addEventListener("resize", () => {
            setWidth(window.innerWidth);
        });

        if (activeGoalList2 !== undefined) {
            if (activeIndex >= 0 && activeIndex <= activeGoalList2.length - 1) {
                setActive(activeGoalList2[activeIndex]);
            } else {
                if (activeIndex <= 0) {
                    setActiveIndex(0);
                } else {
                    setActiveIndex(activeGoalList2.length - 1);
                }
                if (activeGoalList2.length == 0) {
                    setActive(activeGoalList[activeIndex]);
                }
            }
        }
        if (activeSkillList !== undefined) {
            if (threatIndex >= 0 && threatIndex <= activeSkillList.length - 1) {
                setThreat(activeSkillList[threatIndex]);
            } else {
                if (threatIndex <= 0) {
                    setThreatIndex(0);
                } else {
                    setThreatIndex(activeSkillList.length - 1);
                }
            }
        }
        if (LeftActiveGoalList !== undefined) {
            setLeftActive(LeftActiveGoalList);
        }
        console.log(activeGoalList2);

        console.log(activeSkillList);
        if (
            activeGoalList !== undefined &&
            activeSkillList !== undefined &&
            materialsList !== undefined
        ) {
            setLoadPage(false);
        }
        if (
            isLoadingGoal === true ||
            isLoadingSkill === true ||
            isLoadingMaterial === true ||
            activeGoalList.length == 1
        ) {
            setLoadActiveInfo(true);
        } else {
            setLoadActiveInfo(false);
        }
    }, [
        width,
        activeGoalList,
        activeGoalList2,
        activeIndex,
        active,
        threatIndex,
        threat,
        activeSkillList,
        LeftActiveGoalList,
        materialsList,
        isLoadingGoal,
        isLoadingSkill,
    ]);

    // TODO добавить крутилку
    if (isUserLoading || isUserError) return <Loader text="Загрузка..." />;

    return (
        // <section className="relative flex h-full w-full flex-col items-center overflow-y-scroll px-[16px] pb-[26px] max-[834px]:pb-[76px]">
        //     <div className="mt-[30px] flex w-full max-w-[1415px]">
        //         <div className="mr-[10px] flex w-full max-w-[1415px] flex-col gap-[65px] max-[834px]:gap-[60px]">
        //             <div className="flex flex-col gap-[40px]">
        //                 <h2 className="text-[20px] font-bold leading-[24px] text-[#264354] max-[834px]:text-[16px] max-[834px]:leading-[19.2px]">
        //                     Здравствуй, {user?.first_name}!
        //                 </h2>
        //                 <p className="text-tr-m font-semibold text-[#264354]">
        //                     Продолжайте достигать цели:
        //                 </p>
        //             </div>
        //         </div>
        //     </div>
        //     {loadPage === false ? (
        //         <div className="flex w-full max-w-[1415px] gap-x-[25px] mt-[20px]">
        //             <div className={`absolute z-40 flex flex-col w-full max-w-[575px] ${openList === true ? " min-h-[665px]":" h-full max-h-[665px]"}  bg-[#3F4A50] rounded-xl py-[25px] px-[20px] `}>
        //                 <div className="flex w-full justify-between mt-[20px] items-start">
        //                     <div className="flex flex-col gap-y-[15px] text-white text-tr-s font-medium w-[375px]">
        //                         <p className="text-tr-s text-white font-medium">
        //                             Вы уже начали достигать цель
        //                         </p>
        //                         <p className="text-tr-l text-white font-bold break-words">
        //                             {leftActive?.active.name}
        //                         </p>
        //                     </div>
        //                     <ProgressCircle
        //                         className="w-[144px] h-[144px]"
        //                         colorLine="#FCA86F"
        //                         colorText="white"
        //                         ringType="big"
        //                         progress={(leftActive?.completed_threats_count / leftActive?.threats_count)}
        //                     />
        //                 </div>
        //                 <div className="flex flex-col gap-y-[20px] mt-[25px] h-full">
        //                     {leftactiveSkillList !== undefined && (
        //                         <>
        //                             {leftactiveSkillList?.map((skill) => {
        //                                 return (
        //                                     <div className="group opacity-50 hover:opacity-100 flex flex-col w-full max-w-[420px] gap-y-[10px]">
        //                                         <LeftSkill skill={skill}/>

        //                                         <ProgressBar
        //                                             background="#369F48"
        //                                             color="#369F48"
        //                                             height="3px"
        //                                             fontSize=""
        //                                             lineHeight=""
        //                                             top=""
        //                                             right=""
        //                                             percent={(skill?.completed_content_count / skill?.content_count)}
        //                                         />
        //                                     </div>
        //                                 );
        //                             })}
        //                         </>
        //                     )}
        //                 </div>
        //                 <div className={` flex w-full justify-between mt-[20px] items-center `}>
        //                     <>
        //                         {leftactiveSkillList?.length > 7 ? (
        //                             <>
        //                             {openList === false ? (
        //                                 <div
        //                                         className="flex gap-x-[5px] text-white text-tr-s font-medium cursor-pointer"
        //                                         onClick={() => setOpenList(true)}
        //                                     >
        //                                         <p>
        //                                             и еще {leftactiveSkillList?.length - 7} навыков
        //                                         </p>
        //                                         &#8595;
        //                                     </div>
        //                                 ) : (
        //                                     <div
        //                                         className="flex gap-x-[5px] text-white text-tr-s font-medium cursor-pointer"
        //                                         onClick={() => setOpenList(false)}
        //                                     >
        //                                         <p>
        //                                             Свернуть
        //                                         </p>
        //                                         &#8593;
        //                                     </div>
        //                                 )
        //                             }
        //                             </>
        //                         ) : (
        //                             <div>
        //                             </div>
        //                         )}
        //                     </>
        //                     <>
        //                     {leftActive !== undefined && (
        //                         <div
        //                             className="cursor-pointer flex rounded-full h-[55px] w-[55px] bg-white rotate-45 hover:-rotate-45 items-center justify-center text-[#264354B2] text-[30px]"
        //                             onClick={() => handleLinkGoal(leftActive.id)}
        //                         >
        //                             &#8594;
        //                         </div>
        //                     )}
        //                     </>
        //                 </div>
        //             </div>

        //             <div className="flex flex-col w-full max-w-[455px] h-full max-h-[665px] rounded-xl gap-y-[20px] ml-[600px]">
        //                 <div className="pt-[20px] px-[20px] pb-[10px] flex flex-col h-full max-h-[190px] w-full max-w-[455px] rounded-xl shadow-[0px_7px_22px_0px_#2643541A]">
        //                     <div className="flex w-full justify-between">
        //                         <p className="text-tr-s font-medium text-[#264354]">
        //                             Средняя эффективность
        //                         </p>
        //                         <div className="flex gap-x-[10px] items-center cursor-pointer">
        //                             <p className="text-tr-s text-[#264354] font-normal">
        //                                 Неделя
        //                             </p>
        //                             <ListOpen className="mb-[2px]"/>
        //                         </div>
        //                     </div>

        //                     <WeekProgress weekData={weekProgress} />

        //                 </div>

        //                 {/* <div className="p-[20px] flex flex-col h-full max-h-[455px] w-full max-w-[455px] rounded-xl shadow-[0px_7px_22px_0px_#2643541A]">
        //                     <p className="text-tr-s text-main-dark font-semibold">
        //                         Ваши достижения
        //                     </p>

        //                     <div className="grid grid-cols-2 gap-x-2 gap-y-2 mt-[20px] h-full">
        //                         <div className="group flex flex-col w-full max-h-[145px] grow border rounded gap-y-[20px] items-center justify-center cursor-pointer">
        //                             <Target/>
        //                             <p className="text-tr-xs text-main-dark group-hover:text-orange font-medium">Достижение 1</p>
        //                         </div>
        //                         <div className="group flex flex-col w-full max-h-[145px] border rounded gap-y-[20px] items-center justify-center cursor-pointer">
        //                             <Target/>
        //                             <p className="text-tr-xs text-main-dark group-hover:text-orange  font-medium">Достижение 2</p>
        //                         </div>
        //                         <div className="group flex flex-col w-full max-h-[145px]  border rounded gap-y-[20px] items-center justify-center cursor-pointer">
        //                             <Target/>
        //                             <p className="text-tr-xs text-main-dark group-hover:text-orange  font-medium">Достижение 3</p>
        //                         </div>
        //                         <div className="group flex flex-col w-full max-h-[145px] border rounded gap-y-[20px] items-center justify-center cursor-pointer">
        //                             <Target/>
        //                             <p className="text-tr-xs text-main-dark group-hover:text-orange  font-medium">Достижение 4</p>
        //                         </div>
        //                     </div>
        //                     <div className="flex w-full justify-between mt-[20px] items-center">
        //                         <div className="flex gap-x-[5px] text-main-dark text-tr-s font-medium cursor-pointer">
        //                             <p>
        //                                 и еще 6 достижений
        //                             </p>
        //                             &#8595;
        //                         </div>
        //                         <div className="flex rounded-full h-[55px] w-[55px] bg-[#264354B2] rotate-45 hover:-rotate-45 items-center justify-center text-white text-[30px] cursor-pointer">
        //                             &#8594;
        //                         </div>
        //                     </div>

        //                 </div> */}

        //                 <div className="p-[20px] flex flex-col h-full max-h-[455px] w-full max-w-[455px] rounded-xl shadow-[0px_7px_22px_0px_#2643541A]">
        //                     <p className="text-tr-s text-main-dark font-semibold">
        //                         Ваши достижения
        //                     </p>
        //                     <div className="flex flex-col h-full w-fullrounded-xl items-center justify-center gap-y-[15px]">
        //                         <EmptyAchievements />
        //                         <p className="text-tr-s font-semibold text-main-dark">Пока что тут пусто</p>
        //                     </div>

        //                 </div>
        //             </div>
        //             {loadActiveInfo === false ? (
        //             <div className="flex flex-col w-full max-w-[335px] h-full max-h-[665px] rounded-xl shadow-[0px_7px_22px_0px_#2643541A] items-center px-[30px] py-[25px]">
        //                 <p className="text-tr-m font-medium text-main-dark">Ваш прогресс по целям</p>
        //                     {goalsList.results.length !== 0 ? (
        //                         <>
        //                             <div className="flex w-full justify-between items-center mt-[20px]">
        //                                 <div onClick={() =>  setActiveIndex(activeIndex - 1)} className="flex hover:bg-blue hover:bg-opacity-20 h-[35px] w-[35px] rounded-full items-center justify-center cursor-pointer">
        //                                     <ListOpen className="rotate-90 " />
        //                                 </div>
        //                                     <ProgressCircle
        //                                         className="w-[115px] h-[115px]"
        //                                         colorLine="#FCA86F"
        //                                         colorText="black"
        //                                         ringType="middle"
        //                                         progress={(active?.completed_threats_count / active?.threats_count)}
        //                                     />
        //                                 <div onClick={() => setActiveIndex(activeIndex + 1)} className="flex hover:bg-blue hover:bg-opacity-20 h-[35px] w-[35px] rounded-full items-center justify-center cursor-pointer">
        //                                     <ListClose className="rotate-90 " />
        //                                 </div>
        //                             </div>

        //                             <p className="text-tr-s font-medium text-main-dark mt-[10px]">{active?.active.name}</p>

        //                             <div className="flex w-full justify-between items-center mt-[30px] ">
        //                                 <div onClick={() =>  setThreatIndex(threatIndex - 1)} className="flex hover:bg-blue hover:bg-opacity-20 h-[35px] w-[35px] rounded-full items-center justify-center cursor-pointer">
        //                                     <ListOpen className="rotate-90 " />
        //                                 </div>
        //                                     <ProgressCircle
        //                                         className="w-[80px] h-[80px]"
        //                                         colorLine="#369F48"
        //                                         colorText="black"
        //                                         ringType="short"
        //                                         progress={(threat?.completed_content_count / threat?.content_count)}
        //                                     />
        //                                 <div  onClick={() => setThreatIndex(threatIndex + 1)} className="flex hover:bg-blue hover:bg-opacity-20 h-[35px] w-[35px] rounded-full items-center justify-center cursor-pointer">
        //                                     <ListClose className="rotate-90 "/>
        //                                 </div>
        //                             </div>

        //                             <p className="text-tr-s font-medium text-main-dark mt-[10px]">{threat?.threat.name}</p>

        //                             <div className="flex flex-col w-full gap-y-[10px] h-[280px] items-center overflow-y-auto">
        //                                 {materialsList !== undefined && (
        //                                     <>
        //                                         {materialsList?.results.map((material) => {
        //                                             return (
        //                                                 <div
        //                                                     onClick={() => handleClickLinkThreat(threat.id, material.id)}
        //                                                     className="group hover:bg-blue hover:bg-opacity-20 flex w-full max-w-[295px] h-full max-h-[60px] p-[10px] cursor-pointer justify-between"
        //                                                 >
        //                                                     <div className="flex flex-col w-full gap-y-[5px]">
        //                                                         <p className="text-tr-xs font-normal text-[#566DA3]">{material.content_type.verbose_name}</p>
        //                                                         <p className="text-tr-s font-medium text-[#26435499] underline">{material.name}</p>
        //                                                     </div>
        //                                                     <div className="opacity-0 group-hover:opacity-100">
        //                                                         &#8594;
        //                                                     </div>
        //                                                 </div>
        //                                             );
        //                                         })}
        //                                     </>
        //                                 )}
        //                             </div>

        //                             <div
        //                                 onClick={handleClickLink}
        //                                 className="flex w-full max-w-[295px] h-full max-h-[35px] rounded-full bg-[#566DA3] text-white items-center justify-center cursor-pointer">
        //                                 Изучить сразу
        //                             </div>
        //                         </>
        //                     ):(
        //                         <>
        //                             Пока что нет добавленных целей
        //                         </>
        //                     )}
        //             </div>
        //             ):(
        //                 <ActiveInfoSkeleton />
        //             )}
        //         </div>
        //     ) : (
        //         <MainGoalSkeleton />
        //     )}

        //     <div className="flex w-full max-w-[1415px] h-full mt-[40px] mb-[100px]">
        //         <div className="flex flex-col w-full">
        //             <p className="text-tr-m font-semibold text-[#264354]">
        //                 События
        //             </p>
        //             <div className="flex w-full h-[370px] justify-between mt-[40px]">
        //                 <Calendar />
        //                 <div className="flex flex-col w-full max-w-[825px]">
        //                     <p className="text-tr-m text-[#264354] font-semibold ">Все события</p>
        //                     <p className="text-tr-m text-[#264354] font-normal mt-[15px]">20 августа 2024</p>

        //                     {/* {openCard === false ? (
        //                         <div className="flex justify-between w-full mt-[20px] items-center border-b-[1px] border-b-black py-[10px] px-5">
        //                             <div className="flex flex-col w-full gap-y-[10px]">
        //                                 <div className="flex gap-x-[5px]">
        //                                     <p className="text-tr-m text-[#264354] font-medium" >20 августа 2024</p>
        //                                     <p className="text-tr-m text-[#264354] font-medium">14:00</p>
        //                                     <div className="flex w-full max-w-[355px] rounded bg-[#F37D73] bg-opacity-20 justify-center items-center">
        //                                         <p className="text-tr-s text-[#F37D73] font-normal">приближается дедлайн по учебному материалу</p>
        //                                     </div>
        //                                 </div>
        //                                 <p className="text-tr-s text-[#264354] font-medium">Название веб ресурса</p>
        //                             </div>
        //                             <ListOpen onClick={() => setOpenCard(true)} className="cursor-pointer"/>
        //                         </div>
        //                     ):(
        //                         <div className="flex flex-col gap-y-[10px] mt-[20px]  py-[10px] px-5 shadow-[0px_0px_15px_0px_#2643541A] rounded-md">
        //                             <div className="flex justify-between w-full items-center">
        //                                 <div className="flex flex-col w-full gap-y-[7px]">
        //                                     <div className="flex gap-x-[5px]">
        //                                         <p className="text-tr-m text-[#264354] font-medium" >20 августа 2024</p>
        //                                         <p className="text-tr-m text-[#264354] font-medium">14:00</p>
        //                                         <div className="flex w-full max-w-[355px] rounded bg-[#F37D73] bg-opacity-20 justify-center items-center">
        //                                             <p className="text-tr-s text-[#F37D73] font-normal">приближается дедлайн по учебному материалу</p>
        //                                         </div>
        //                                     </div>
        //                                     <p className="text-tr-s text-[#264354] font-medium">Название веб ресурса</p>
        //                                 </div>
        //                                 <ListClose onClick={() => setOpenCard(false)} className="cursor-pointer"/>
        //                             </div>

        //                             <div className="flex justify-between w-full items-center">
        //                                 <div className="flex flex-col w-full gap-y-[7px]">
        //                                     <p className="text-tr-s text-[#264354] font-medium">Веб-ресурс</p>
        //                                     <div className="flex gap-x-[5px]">
        //                                         <p className="text-tr-m text-[#264354] font-medium" >Время прохождения</p>
        //                                         <div className="flex w-full max-w-[70px] h-[20px] rounded bg-[#566DA333] bg-opacity-20 justify-center items-center">
        //                                             <p className="text-tr-s text-[#264354] font-normal">15 минут</p>
        //                                         </div>
        //                                     </div>

        //                                 </div>
        //                                 <div className="group hover:bg-[#264354] hover:text-white flex w-full max-w-[140px] h-full max-h-[35px] rounded-full items-center justify-between shadow-[0px_0px_15px_0px_#2643541A] cursor-pointer px-[20px]">
        //                                     Изучить
        //                                     <EyeIcon className="fill-[#264354] group-hover:fill-white"/>
        //                                 </div>

        //                             </div>
        //                         </div>
        //                     )} */}
        //                     <div className="flex items-center w-full h-full ">
        //                         <GirlDontKnow className="mt-[30px] ml-[20px]"/>
        //                         <p className="text-tr-m text-main-dark font-medium">Событий не запланировано</p>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </section>

        <section className="relative flex h-full w-full flex-col items-center overflow-y-scroll px-[5px] pb-[26px] max-[834px]:pb-[76px]">
            <div className="mt-[30px] flex w-full max-w-[1415px]">
                <div className="mr-[10px] flex w-full max-w-[1415px] flex-col gap-[65px] max-[834px]:gap-[60px]">
                    <div className="flex flex-col gap-[40px]">
                        <h2 className="text-[20px] font-bold leading-[24px] text-[#264354] max-[834px]:text-[16px] max-[834px]:leading-[19.2px]">
                            Здравствуй, {user?.first_name}!
                        </h2>
                        <p className="text-tr-m font-semibold text-[#264354]">
                            Продолжайте достигать цели:
                        </p>
                    </div>
                </div>
            </div>
            {loadPage === false ? (
                <div className="flex w-full max-w-[1415px]">
                    <div
                        className="
                        gap-x-[25px] 
                        gap-y-[25px] 
                        max-[800px]:flex
                        max-[800px]:w-full
                        max-[800px]:flex-col
                        max-[800px]:items-center
                        min-[800px]:grid 
                        min-[800px]:grid-cols-[minmax(335px,455px),minmax(420px,455px)] 
                        min-[800px]:grid-rows-3 
                        min-[1070px]:grid-cols-[minmax(420px,575px),minmax(330px,455px),minmax(250px,665px)] 
                        min-[1070px]:grid-rows-2 "
                    >
                        <div className="relative flex w-full">
                            <div
                                className={` z-40 flex w-full flex-col  ${
                                    openList === true
                                        ? "absolute left-0 right-0 min-h-[665px] w-full"
                                        : " h-full max-h-[665px]"
                                }  rounded-xl bg-[#3F4A50] px-[20px] py-[25px] `}
                            >
                                <div className="mt-[20px] flex w-full items-start justify-between">
                                    <div className="flex w-[375px] flex-col gap-y-[15px] text-tr-s font-medium text-white">
                                        <p className="text-tr-s font-medium text-white">
                                            Вы уже начали достигать цель
                                        </p>
                                        <p className="break-words text-tr-l font-bold text-white">
                                            {leftActive?.active.name}
                                        </p>
                                    </div>
                                    <ProgressCircle
                                        className="h-[144px] w-[144px]"
                                        colorLine="#FCA86F"
                                        colorText="white"
                                        ringType="big"
                                        progress={
                                            leftActive?.completed_threats_count /
                                            leftActive?.threats_count
                                        }
                                    />
                                </div>
                                <div className="mt-[25px] flex h-full flex-col gap-y-[20px]">
                                    {leftactiveSkillList !== undefined && (
                                        <>
                                            {leftactiveSkillList?.map(
                                                (skill) => {
                                                    return (
                                                        <div className="group flex w-full max-w-[420px] flex-col gap-y-[10px] opacity-50 hover:opacity-100">
                                                            <LeftSkill
                                                                skill={skill}
                                                            />

                                                            <ProgressBar
                                                                background="#369F48"
                                                                color="#369F48"
                                                                height="3px"
                                                                fontSize=""
                                                                lineHeight=""
                                                                top=""
                                                                right=""
                                                                percent={
                                                                    skill?.completed_content_count /
                                                                    skill?.content_count
                                                                }
                                                            />
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </>
                                    )}
                                </div>
                                <div
                                    className={` mt-[20px] flex w-full items-center justify-between `}
                                >
                                    <>
                                        {leftactiveSkillList?.length > 7 ? (
                                            <>
                                                {openList === false ? (
                                                    <div
                                                        className="flex cursor-pointer gap-x-[5px] text-tr-s font-medium text-white"
                                                        onClick={() =>
                                                            setOpenList(true)
                                                        }
                                                    >
                                                        <p>
                                                            и еще{" "}
                                                            {leftactiveSkillList?.length -
                                                                7}{" "}
                                                            навыков
                                                        </p>
                                                        &#8595;
                                                    </div>
                                                ) : (
                                                    <div
                                                        className="flex cursor-pointer gap-x-[5px] text-tr-s font-medium text-white"
                                                        onClick={() =>
                                                            setOpenList(false)
                                                        }
                                                    >
                                                        <p>Свернуть</p>
                                                        &#8593;
                                                    </div>
                                                )}
                                            </>
                                        ) : (
                                            <div></div>
                                        )}
                                    </>
                                    <>
                                        {leftActive !== undefined && (
                                            <div
                                                className="flex h-[55px] w-[55px] rotate-45 cursor-pointer items-center justify-center rounded-full bg-white text-[30px] text-[#264354B2] hover:-rotate-45"
                                                onClick={() =>
                                                    handleLinkGoal(
                                                        leftActive.id
                                                    )
                                                }
                                            >
                                                &#8594;
                                            </div>
                                        )}
                                    </>
                                </div>
                            </div>
                        </div>

                        <div className=" flex h-full max-h-[665px]  w-full flex-col gap-y-[20px] rounded-xl ">
                            <div className="flex h-full max-h-[190px] w-full flex-col rounded-xl px-[20px] pb-[10px] pt-[20px] shadow-[0px_7px_22px_0px_#2643541A]">
                                <div className="flex w-full justify-between">
                                    <p className="text-tr-s font-medium text-[#264354]">
                                        Средняя эффективность
                                    </p>
                                    <div className="flex cursor-pointer items-center gap-x-[10px]">
                                        <p className="text-tr-s font-normal text-[#264354]">
                                            Неделя
                                        </p>
                                        <ListOpen className="mb-[2px]" />
                                    </div>
                                </div>

                                <WeekProgress weekData={weekProgress} />
                            </div>

                            <div className="flex h-full max-h-[455px] w-full flex-col rounded-xl p-[20px] shadow-[0px_7px_22px_0px_#2643541A]">
                                <p className="text-tr-s font-semibold text-main-dark">
                                    Ваши достижения
                                </p>
                                <div className="w-fullrounded-xl flex h-full flex-col items-center justify-center gap-y-[15px]">
                                    <EmptyAchievements />
                                    <p className="text-tr-s font-semibold text-main-dark">
                                        Пока что тут пусто
                                    </p>
                                </div>
                            </div>
                        </div>

                        {loadActiveInfo === false ? (
                            <div className="flex h-full max-h-[665px]  w-full flex-col items-center rounded-xl px-[30px] py-[25px] shadow-[0px_7px_22px_0px_#2643541A]">
                                <p className="text-tr-m font-medium text-main-dark">
                                    Ваш прогресс по целям
                                </p>
                                {goalsList.results.length !== 0 ? (
                                    <>
                                        <div className="mt-[20px] flex w-full items-center justify-between">
                                            <div
                                                onClick={() =>
                                                    setActiveIndex(
                                                        activeIndex - 1
                                                    )
                                                }
                                                className="flex h-[35px] w-[35px] cursor-pointer items-center justify-center rounded-full hover:bg-blue hover:bg-opacity-20"
                                            >
                                                <ListOpen className="rotate-90 " />
                                            </div>
                                            <ProgressCircle
                                                className="h-[115px] w-[115px]"
                                                colorLine="#FCA86F"
                                                colorText="black"
                                                ringType="middle"
                                                progress={
                                                    active?.completed_threats_count /
                                                    active?.threats_count
                                                }
                                            />
                                            <div
                                                onClick={() =>
                                                    setActiveIndex(
                                                        activeIndex + 1
                                                    )
                                                }
                                                className="flex h-[35px] w-[35px] cursor-pointer items-center justify-center rounded-full hover:bg-blue hover:bg-opacity-20"
                                            >
                                                <ListClose className="rotate-90 " />
                                            </div>
                                        </div>

                                        <p className="mt-[10px] text-tr-s font-medium text-main-dark">
                                            {active?.active.name}
                                        </p>

                                        <div className="mt-[30px] flex w-full items-center justify-between ">
                                            <div
                                                onClick={() =>
                                                    setThreatIndex(
                                                        threatIndex - 1
                                                    )
                                                }
                                                className="flex h-[35px] w-[35px] cursor-pointer items-center justify-center rounded-full hover:bg-blue hover:bg-opacity-20"
                                            >
                                                <ListOpen className="rotate-90 " />
                                            </div>
                                            <ProgressCircle
                                                className="h-[80px] w-[80px]"
                                                colorLine="#369F48"
                                                colorText="black"
                                                ringType="short"
                                                progress={
                                                    threat?.completed_content_count /
                                                    threat?.content_count
                                                }
                                            />
                                            <div
                                                onClick={() =>
                                                    setThreatIndex(
                                                        threatIndex + 1
                                                    )
                                                }
                                                className="flex h-[35px] w-[35px] cursor-pointer items-center justify-center rounded-full hover:bg-blue hover:bg-opacity-20"
                                            >
                                                <ListClose className="rotate-90 " />
                                            </div>
                                        </div>

                                        <p className="mt-[10px] text-tr-s font-medium text-main-dark">
                                            {threat?.threat.name}
                                        </p>

                                        <div className="flex h-[280px] w-full flex-col gap-y-[10px] overflow-y-auto ">
                                            {materialsList !== undefined && (
                                                <>
                                                    {materialsList?.results.map(
                                                        (material) => {
                                                            return (
                                                                <div
                                                                    onClick={() =>
                                                                        handleClickLinkThreat(
                                                                            threat.id,
                                                                            material.id
                                                                        )
                                                                    }
                                                                    className="group flex h-full max-h-[60px] w-full max-w-[295px] cursor-pointer justify-between p-[10px] hover:bg-blue hover:bg-opacity-20"
                                                                >
                                                                    <div className="flex w-full flex-col gap-y-[5px]">
                                                                        <p className="text-tr-xs font-normal text-[#566DA3]">
                                                                            {
                                                                                material
                                                                                    .content_type
                                                                                    .verbose_name
                                                                            }
                                                                        </p>
                                                                        <p className="text-tr-s font-medium text-[#26435499] underline">
                                                                            {
                                                                                material.name
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                    <div className="opacity-0 group-hover:opacity-100">
                                                                        &#8594;
                                                                    </div>
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                                </>
                                            )}
                                        </div>

                                        <div
                                            onClick={handleClickLink}
                                            className="flex h-full max-h-[35px] w-full max-w-[295px] cursor-pointer items-center justify-center rounded-full bg-[#566DA3] text-white"
                                        >
                                            Изучить сразу
                                        </div>
                                    </>
                                ) : (
                                    <>Пока что нет добавленных целей</>
                                )}
                            </div>
                        ) : (
                            <ActiveInfoSkeleton />
                        )}
                        <Calendar />

                        <Events />
                    </div>
                </div>
            ) : (
                <MainGoalSkeleton />
            )}
        </section>
    );
}

export default MainPage;
