import * as http from "shared/http";
import useSWR from "swr";
import * as types from "shared/types";

export function getUserWeekProgress(url: string) {
    return http.request<types.WeekProgress[]>({
        method: "get",
        url: url,
    });
}

export default function useUserWeekProgress() {
    const { data, error } = useSWR(
        `/api/v1/user/week_progress`,
        getUserWeekProgress
    );

    return {
        weekProgress: data,
        isLoadingMaterial: !error && !data,
        isError: error,
    };
}