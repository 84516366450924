import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import useSkillsGoal from "shared/api/useSkillsGoal";
import { Loader } from "components/Loader/Loader";
import { useStore } from "effector-react";
import * as types from "shared/types";
import { ReactComponent as SkillIconGreen } from "shared/image/icon/SkillIconGreen.svg";
import { ReactComponent as Left } from "shared/ui/icon/Left.svg";
import { ReactComponent as Right } from "shared/ui/icon/Right.svg";
import { CardMaterialTimeline } from "components/CardMaterialTimeline/CardMaterialTimeline";
import { OrdersPieChartActive } from "components/OrdersPieChart/OrdersPieChartActive";
import { CardThreatTimeline } from "components/CardThreatTimeline/CardThreatTimeline";
import useMaterialsGoal from "shared/api/useMaterialsGoal";
import useGoal from "shared/api/useGoal";
import { MaterialTimeline } from "components/MaterialItem/MaterialTimeline";
import { truncateText } from "utils/truncateText";
import { ReactComponent as SelectedMaterial } from "../../shared/ui/icon/SelectedMaterial.svg";
import { ReactComponent as FinishedMaterial } from "../../shared/ui/icon/FinishedMaterial.svg";

// Новое
import useGoalsList from "shared/api/useGoalsList";
import useMaterialsSkill from "shared/api/useMaterialsSkill";
import { ActiveThreatType, TrajectoryActiveType, MaterialType } from "shared/types";
import { LoaderConstructor } from "./LoaderConstructor/LoaderConstructor";
import {
    CreateModelApi,
    CreatePaginatedModel,
} from "shared/paginatedModelBuilder";
import { ActiveCard } from "./ActiveCard";
import { ThreatCard } from "./ThreatCard";

const TrajectoryActivesModel = CreatePaginatedModel<types.TrajectoryActiveType>("TrajectoryActives");
const ActiveThreatsModel = CreatePaginatedModel<types.ActiveThreatType>("ActiveThreats");
const SkillsContentModel = CreatePaginatedModel<types.MaterialType>("SkillsContent");

export const Timeline: React.FC = () => {
    // const { goalsList, isLoadingGoal, isErrorGoal } = useGoalsList();
    // const { skillList, isLoadingSkill, isErrorSkill } = useSkillsGoal(active?.id);
    // const { materialsList, isLoadingMaterial } = useMaterialsSkill(threat?.id);

    const [isLoadingActive, setIsLoadingActive] = useState(true);
    const [isLoadingThreats, setIsLoadingThreats] = useState(true);
    const [isLoadingContent, setIsLoadingContent] = useState(false);

    const [openThreats, setOpenThreats] = useState(false);
    const [openMaterials, setOpenMaterials] = useState(false);

    const location = useLocation();
    const goal = location.state.leftActive;

    const actives = useStore(TrajectoryActivesModel.store); // Запрос модели цели профиля
    const activesList = actives.results; // Получение результатов модели
    const threat = useStore(ActiveThreatsModel.store); // Запрос модели навыков профиля
    const threatList = threat.results; // Получение результатов модели
    const content = useStore(SkillsContentModel.store); // Получение модели умов навыка
    const contentList = content.results; // Получение результатов модели

    const activeGoalList = activesList?.filter(
        (item) => item.content_count > 0
    );
    const activeListLenght = activeGoalList.length;
    
    const activeSkillList = threatList.filter(
        (item) => item.content_count > 0
    );

    const [editActive, setEditActive] = useState<TrajectoryActiveType>(goal);
    const [editThreat, setEditThreat] = useState<ActiveThreatType>();
    const [editMaterial, setEditMaterial] = useState<MaterialType>();
    const [openedThreat, setOpenedThreat] = useState("");

    const ModelApi1 = CreateModelApi(
        TrajectoryActivesModel,
        `/api/v1/user/trajectory_actives`,
        "",
        150
    ); 

    const ModelApi2 = CreateModelApi(
        ActiveThreatsModel,
        `/api/v1/user/trajectory_active/${editActive?.id}/threats`,
        "",
        150
    ); 

    const ModelApi3 = CreateModelApi(
        SkillsContentModel,
        `/api/v1/user/active_threat/${editThreat?.id}/content`,
        "",
        150
    ); 

    // Старое

    const [openList, setOpenList] = useState(false);
    const [skillId, setSkillId] = useState(null);

    // const { skillList, isLoadingSkill } = useSkillsGoal(goal?.id);
    const { dataGoal } = useGoal(goal?.id);
    // const [skillName, setSkillName] = useState(skillList?.results[0]?.threat?.name);
    const navigate = useNavigate();
    const [allMaterials, setAllMaterials] = useState(true);
    // const materials = useStore(ContentModel.store);
    const [width, setWidth] = useState(window.innerWidth);
    const materialGoal = useMaterialsGoal(goal?.id);
    const completedMaterialCount = materialGoal.materialsList?.results.filter(
        (item) => item.session.completion_status === "completed"
    ).length;
    const [selectedThreat, setSelectedThreat] = useState<
        types.ActiveThreatType | undefined
    >();

    const handleClickLinkGoal = (evt) => {
        evt.stopPropagation();
        navigate("/goals");
    };

    const handleClickLinkMain = (evt) => {
        evt.stopPropagation();
        navigate("/");
    };

    const handleClickLinkBack = (evt) => {
        evt.stopPropagation();
        navigate(-1);
    };

    // const ModelApi = CreateModelApi(
    //     ContentModel,
    //     `/api/v1/user/active_threat/${skillId}/content`,
    //     ""
    // );

    // const ModelApiGoal = CreateModelApi(
    //     ActivesModel,
    //     `/api/v1/trajectory_active/${goal?.id}`,
    //     ""
    // );

    const scrollLeft = () => {
        const ul = document.querySelector(".container-scroll");
        ul.scrollBy(-250, 0);
    };

    const scrollRight = () => {
        const ul = document.querySelector(".container-scroll");
        ul.scrollBy(250, 0);
    };

    // useEffect(() => {
    //     if (skillId) {
    //         ModelApi.loadFirstPage().then(() => console.log());
    //     }
    //     ModelApiGoal.loadFirstPage().then(() => console.log());
    // }, [skillId]);

    useEffect(() => {
        window.addEventListener("resize", () => {
            setWidth(window.innerWidth);
        });
        if (isLoadingActive) {
            ModelApi1.loadFirstPage().then(() => {
                setIsLoadingActive(false);
            });
        }
        if (isLoadingThreats) {
            ModelApi2.loadFirstPage().then(() => {
                setIsLoadingThreats(false);
            });
        }
        if (isLoadingContent) {
            ModelApi3.loadFirstPage().then(() => {
                setIsLoadingContent(false);
            });
        }
    }, [width, isLoadingActive, isLoadingThreats, isLoadingContent]);

    // useEffect(() => {
    //     if (skillList?.results?.length > 0) {
    //         setSkillName(skillList?.results[0]?.threat?.name);
    //     }
    // }, [skillList]);

    // materials?.results?.map((item) => console.log(item));
    // console.log(materialGoal);
    // if (isLoadingSkill) return <Loader text="Загрузка..." />;

    return (
        // <main className="flex w-full flex-col overflow-auto">
        //     <section className="relative flex w-full justify-center">
        //         <div className="mb-[10px] mt-[30px] flex w-full max-w-[1415px] justify-between px-[16px] max-[834px]:mb-[60px]">
        //             <div className="flex w-full flex-col">
        //                 <div className="mb-[45px] flex flex-col gap-[19px] max-[834px]:mb-[40px]">
        //                     <p className="cursor-pointer text-[14px] font-normal leading-[16.8px] text-[#2643544D]">
        //                         <span onClick={handleClickLinkBack}>
        //                             &#8592;
        //                         </span>{" "}
        //                         <span onClick={handleClickLinkMain}>
        //                             Главная
        //                         </span>{" "}
        //                         <span onClick={handleClickLinkGoal}>/Цели</span>
        //                     </p>
        //                     <h2 className="text-[20px] font-bold leading-[24px] text-[#264354]">
        //                         {goal?.active?.name}
        //                     </h2>
        //                 </div>
        //                 <p className="mb-[10px] text-[20px] font-medium leading-[24px] text-[#264354] opacity-[80%] max-[834px]:text-[16px] max-[834px]:leading-[19.2px]">
        //                     Прогресс по достижению цели
        //                 </p>
        //                 <div className="flex items-end justify-between">
        //                     <div className="mr-[10px] flex w-full max-w-[493px] items-end gap-[40px]">
        //                         <div className="h-[214px] w-[100%] max-w-[214px] max-[834px]:h-[169px] max-[834px]:max-w-[169px]">
        //                             <OrdersPieChartActive goal={dataGoal} />
        //                         </div>
        //                         <div className="flex flex-col gap-[29px]">
        //                             <p className="text-[16px] font-medium leading-[19.2px] text-[#264354] opacity-[80%]">
        //                                 Изучено учебных материалов
        //                             </p>
        //                             <div className="flex items-end gap-[12px]">
        //                                 <p className="text-[32px] font-medium leading-[38.4px] text-[#566DA3] max-[1010px]:text-[24px] max-[1010px]:leading-[28.8px]">
        //                                     {completedMaterialCount}/
        //                                     {materialGoal.materialsList?.results?.length}
        //                                 </p>
        //                             </div>
        //                         </div>
        //                     </div>
        //                     {/* <CardMaterialMain /> */}
        //                 </div>
        //                 <div className="mt-[50px] hidden flex-col gap-[25px] pb-[20px] max-[834px]:flex">
        //                     <p className="mb-[21px] flex text-[20px] font-medium leading-[24px] text-[#264354] opacity-[80%] max-[834px]:text-[16px] max-[834px]:leading-[19.2px]">
        //                         Учебных материалов в текущей цели
        //                     </p>
        //                     {skillList?.results?.length !== 0 ? (
        //                         <ul className="hidden h-full w-full flex-col items-center space-y-[30px] overflow-y-scroll pb-[20px] max-[834px]:flex">
        //                             {materialGoal.materialsList?.results?.map((item) => (
        //                                 <li
        //                                     className="mt-[20px] w-full max-w-[1415px] px-[16px]"
        //                                     key={item?.id}
        //                                     children={
        //                                         <MaterialTimeline item={item} />
        //                                     }
        //                                 />
        //                             ))}
        //                         </ul>
        //                     ) : (
        //                         <div className="text-center">Данных нет</div>
        //                     )}
        //                 </div>
        //                 {/* <div className="flex max-h-[311px] w-full max-w-[215px] flex-col gap-[30px] overflow-y-auto">
        //                     <div className="flex items-center gap-[10px]">
        //                         <SkillIconGreen className="h-[30px] w-[16px]" />
        //                         <p className="text-[20px] font-medium leading-[24px] text-[#264354]">
        //                             Навыки в цели
        //                         </p>
        //                     </div>
        //                     <div className="flex flex-col gap-[20px]">
        //                         <div
        //                             className={`flex h-[54px] cursor-pointer ${
        //                                 allMaterials
        //                                     ? "bg-[#369F481A]"
        //                                     : "bg-white"
        //                             }
        //                             items-center justify-between rounded-md bg-[#369F481A] p-[10px] hover:shadow-[0_0_25px_0_rgba(38,67,84,0.1)]`}
        //                             onClick={() => {
        //                                 setAllMaterials(true);
        //                             }}
        //                         >
        //                             <p className="text-[14px] font-medium leading-[16.8px] text-[#264354]">
        //                                 Все учебные материалы
        //                             </p>
        //                             <p className="rounded-full bg-[#566DA3] px-[10px] py-[5px] text-[14px] font-medium leading-[16.8px] text-white">
        //                                 {materialGoal.data?.results
        //                                     ?.length > 100
        //                                     ? "100+"
        //                                     : materialGoal.data?.results
        //                                             ?.length}
        //                             </p>
        //                         </div>
        //                         {data?.results?.length >= 0 ? (
        //                             <div className="flex max-h-[500px] flex-col gap-[20px] pb-[10px]">
        //                                 {data?.results?.map(
        //                                     (item) =>
        //                                         item?.content_count > 0 && (
        //                                             <CardThreatTimeline
        //                                                 item={item}
        //                                                 skillId={skillId}
        //                                                 setSkillId={
        //                                                     setSkillId
        //                                                 }
        //                                                 setSkillName={
        //                                                     setSkillName
        //                                                 }
        //                                                 setAllMaterials={
        //                                                     setAllMaterials
        //                                                 }
        //                                                 allMaterials={
        //                                                     allMaterials
        //                                                 }
        //                                             />
        //                                         )
        //                                 )}
        //                             </div>
        //                         ) : (
        //                             <div className="text-center">
        //                                 Данных нет
        //                             </div>
        //                         )}
        //                     </div>
        //                 </div> */}
        //                 <div className="relative mt-[20px] flex flex-col">
        //                     {openList === false ? (
        //                         <div
        //                             onClick={() => setOpenList(true)}
        //                             className="flex h-[85px] w-full max-w-[600px] cursor-pointer items-center justify-between rounded-full px-[30px] shadow-[0_0_10px_0_rgba(38,67,84,0.1)]"
        //                         >
        //                             {selectedThreat === undefined ? (
        //                                 <>
        //                                     <p className="text-[20px] font-medium leading-[24px] text-[#264354CC]">
        //                                         Все учебные материалы
        //                                     </p>
        //                                     <div className="flex items-center gap-x-[25px]">
        //                                         <p className="h-[25px] min-w-[50px] rounded-full bg-[#566DA3] py-[5px] text-center text-[14px] font-medium leading-[16.8px] text-white">
        //                                             {materialGoal.materialsList?.results
        //                                                 ?.length > 100
        //                                                 ? "100+"
        //                                                 : materialGoal.materialsList
        //                                                       ?.results?.length}
        //                                         </p>
        //                                         <div className="flex text-[20px] text-[#F37D73]">
        //                                             &#9660;
        //                                         </div>
        //                                     </div>
        //                                 </>
        //                             ) : (
        //                                 <>
        //                                     <div className="flex flex-col gap-y-[5px]">
        //                                         <p className="text-[14px] font-medium leading-[16.8px] text-[#2643544D]">
        //                                             Навык
        //                                         </p>
        //                                         <p className="text-[20px] font-medium leading-[24px] text-[#264354CC]">
        //                                             {truncateText(
        //                                                 selectedThreat.threat
        //                                                     .name,
        //                                                 1.5
        //                                             )}
        //                                         </p>
        //                                     </div>
        //                                     <div className="flex items-center gap-x-[25px]">
        //                                         <p className="h-[25px] min-w-[50px] rounded-full bg-[#566DA3] py-[5px] text-center text-[14px] font-medium leading-[16.8px] text-white">
        //                                             {selectedThreat?.content_count >
        //                                             100
        //                                                 ? "100+"
        //                                                 : selectedThreat?.content_count}
        //                                         </p>
        //                                         <div className="flex text-[20px] text-[#F37D73]">
        //                                             &#9660;
        //                                         </div>
        //                                     </div>
        //                                 </>
        //                             )}
        //                         </div>
        //                     ) : (
        //                         <div className="absolute z-50 flex max-h-[415px] w-full max-w-[600px] cursor-pointer flex-col rounded-2xl bg-white shadow-[0_0_10px_0_rgba(38,67,84,0.1)]">
        //                             {selectedThreat === undefined ? (
        //                                 <div
        //                                     onClick={() => (
        //                                         setAllMaterials(true),
        //                                         setOpenList(false)
        //                                     )}
        //                                     className="flex min-h-[85px] w-full max-w-[600px]  items-center justify-between px-[30px]"
        //                                 >
        //                                     <p className="text-[20px] font-medium leading-[24px] text-[#264354CC] ">
        //                                         Все учебные материалы
        //                                     </p>
        //                                     <div className="flex items-center gap-x-[25px]">
        //                                         <p className="h-[25px] min-w-[50px]  rounded-full bg-[#566DA3] py-[5px] text-center text-[14px] font-medium leading-[16.8px] text-white">
        //                                             {materialGoal.materialsList?.results
        //                                                 ?.length > 100
        //                                                 ? "100+"
        //                                                 : materialGoal.materialsList
        //                                                       ?.results?.length}
        //                                         </p>
        //                                         <div className="flex text-[20px] text-[#F37D73]">
        //                                             &#9650;
        //                                         </div>
        //                                     </div>
        //                                 </div>
        //                             ) : (
        //                                 <div
        //                                     onClick={() => setOpenList(false)}
        //                                     className="flex min-h-[85px] w-full max-w-[600px]  items-center justify-between px-[30px]"
        //                                 >
        //                                     <div className="flex flex-col gap-y-[5px]">
        //                                         <p className="text-[14px] font-medium leading-[16.8px] text-[#2643544D]">
        //                                             Навык
        //                                         </p>
        //                                         <p className="text-[20px] font-medium leading-[24px] text-[rgba(38,67,84,0.8)]">
        //                                             {truncateText(
        //                                                 selectedThreat.threat
        //                                                     .name,
        //                                                 1.5
        //                                             )}
        //                                         </p>
        //                                     </div>
        //                                     <div className="flex items-center gap-x-[25px]">
        //                                         <p className="h-[25px] min-w-[50px] rounded-full bg-[#566DA3] py-[5px] text-center text-[14px] font-medium leading-[16.8px] text-white">
        //                                             {selectedThreat?.content_count >
        //                                             100
        //                                                 ? "100+"
        //                                                 : selectedThreat?.content_count}
        //                                         </p>
        //                                         <div className="flex text-[20px] text-[#F37D73]">
        //                                             &#9660;
        //                                         </div>
        //                                     </div>
        //                                 </div>
        //                             )}
        //                             {skillList?.results?.length >= 0 ? (
        //                                 <div className="flex flex-col overflow-y-auto pb-[10px]">
        //                                     {selectedThreat !== undefined && (
        //                                         <div
        //                                             className={`flex h-[80px] w-full cursor-pointer items-center justify-between border-b-[1px] border-b-[#264354CC] p-[30px] hover:shadow-[0_0_25px_0_rgba(38,67,84,0.1)]`}
        //                                             onClick={() => {
        //                                                 setAllMaterials(true);
        //                                                 setOpenList(false);
        //                                                 setSelectedThreat(
        //                                                     undefined
        //                                                 );
        //                                             }}
        //                                         >
        //                                             <p className="text-[20px] font-medium leading-[24px] text-[#264354CC] ">
        //                                                 Все учебные материалы
        //                                             </p>
        //                                             <div className="flex items-center gap-x-[25px]">
        //                                                 <p className="h-[25px] min-w-[50px]  rounded-full bg-[#566DA3] py-[5px] text-center text-[14px] font-medium leading-[16.8px] text-white">
        //                                                     {materialGoal.materialsList
        //                                                         ?.results
        //                                                         ?.length > 100
        //                                                         ? "100+"
        //                                                         : materialGoal
        //                                                               .materialsList
        //                                                               ?.results
        //                                                               ?.length}
        //                                                 </p>
        //                                                 <div className="flex text-[20px] text-[#F37D73]">
        //                                                     &#9650;
        //                                                 </div>
        //                                             </div>
        //                                         </div>
        //                                     )}
        //                                     {skillList?.results?.map(
        //                                         (item) =>
        //                                             item?.content_count > 0 && (
        //                                                 <CardThreatTimeline
        //                                                     item={item}
        //                                                     skillId={skillId}
        //                                                     setSkillId={
        //                                                         setSkillId
        //                                                     }
        //                                                     setSkillName={
        //                                                         setSkillName
        //                                                     }
        //                                                     setAllMaterials={
        //                                                         setAllMaterials
        //                                                     }
        //                                                     allMaterials={
        //                                                         allMaterials
        //                                                     }
        //                                                     setOpenList={
        //                                                         setOpenList
        //                                                     }
        //                                                     setSelectedThreat={
        //                                                         setSelectedThreat
        //                                                     }
        //                                                 />
        //                                             )
        //                                     )}
        //                                 </div>
        //                             ) : (
        //                                 <div className="text-center">
        //                                     Данных нет
        //                                 </div>
        //                             )}
        //                         </div>
        //                     )}

        //                     <div
        //                         className={`${
        //                             !openList ? "mt-[20px]" : "mt-[105px]"
        //                         } flex gap-[25px] pb-[20px] max-[834px]:hidden`}
        //                     >
        //                         <div className="flex w-full flex-col justify-between overflow-auto max-[834px]:hidden">
        //                             {!allMaterials ? (
        //                                 materials?.results?.length > 0 ? (
        //                                     <>
        //                                         <ul
        //                                             className="container-scroll flex py-[10px] pr-[10px]"
        //                                             style={{
        //                                                 overflowX: "hidden",
        //                                             }}
        //                                         >
        //                                             {materials?.results?.map(
        //                                                 (item) => (
        //                                                     <CardMaterialTimeline
        //                                                         activeId={
        //                                                             goal.id
        //                                                         }
        //                                                         item={item}
        //                                                         skillName={
        //                                                             skillName
        //                                                         }
        //                                                         allMaterials={
        //                                                             allMaterials
        //                                                         }
        //                                                     />
        //                                                 )
        //                                             )}
        //                                         </ul>
        //                                         <div className="mt-[30px] flex cursor-pointer justify-center gap-x-[45px]">
        //                                             <Left
        //                                                 onClick={scrollLeft}
        //                                             />
        //                                             <Right
        //                                                 onClick={scrollRight}
        //                                             />
        //                                         </div>
        //                                     </>
        //                                 ) : (
        //                                     <div className="flex h-full w-full items-center justify-center">
        //                                         Данных нет
        //                                     </div>
        //                                 )
        //                             ) : materialGoal.materialsList?.results?.length >
        //                               0 ? (
        //                                 <>
        //                                     <ul
        //                                         className="container-scroll flex py-[10px] pr-[10px]"
        //                                         style={{ overflowX: "hidden" }}
        //                                     >
        //                                         {materialGoal.materialsList?.results?.map(
        //                                             (item) => (
        //                                                 <CardMaterialTimeline
        //                                                     activeId={goal.id}
        //                                                     item={item}
        //                                                     skillName={
        //                                                         skillName
        //                                                     }
        //                                                     allMaterials={
        //                                                         allMaterials
        //                                                     }
        //                                                 />
        //                                             )
        //                                         )}
        //                                     </ul>
        //                                     <div className="mt-[30px] flex cursor-pointer justify-center gap-x-[45px]">
        //                                         <Left onClick={scrollLeft} />
        //                                         <Right onClick={scrollRight} />
        //                                     </div>
        //                                 </>
        //                             ) : (
        //                                 <div className="flex h-full w-full items-center justify-center">
        //                                     Данных нет
        //                                 </div>
        //                             )}
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </section>
        // </main>
        <main className="flex w-full flex-col overflow-auto">
            <section className="relative flex w-full justify-center">
                <div className="mb-[10px] mt-[30px] flex w-full max-w-[1415px] justify-between px-[16px] max-[834px]:mb-[60px]">
                    <div className="flex w-full flex-col h-screen">
                        <div className="mb-[45px] flex flex-col gap-[19px] max-[834px]:mb-[40px]">
                            <p className="cursor-pointer text-[14px] font-normal leading-[16.8px] text-[#2643544D]">
                                <span onClick={handleClickLinkBack}>
                                    &#8592;
                                </span>{" "}
                                <span onClick={handleClickLinkMain}>
                                    Главная
                                </span>{" "}
                                <span onClick={handleClickLinkGoal}>/Цели</span>
                            </p>
                            <h2 className="text-[20px] font-bold leading-[24px] text-[#264354]">
                                {goal?.active?.name}
                            </h2>
                        </div>
                        {isLoadingActive === false && isLoadingThreats === false ? (
                            <div className="flex w-full gap-x-[30px]">
                                <div className="flex flex-col gap-y-[40px]">
                                    <p className="text-tr-l font-medium text-main-dark">Все цели</p>
                                    <div className="flex flex-col">
                                        {activeGoalList !== undefined && (
                                            <>
                                                {activeGoalList?.map((active, index) => {
                                                    return (
                                                        <ActiveCard 
                                                            active={active}
                                                            setEditActive={setEditActive}
                                                            setIsLoadingThreats={setIsLoadingThreats}
                                                            setOpenMaterials={setOpenMaterials}
                                                            setOpenThreats={setOpenThreats}
                                                            activeSelected={editActive}
                                                            index={index}
                                                            activeListLenght={activeListLenght}
                                                        />
                                                    );
                                                })}
                                            </>
                                        )}      
                                    </div>
                                </div>
                                <div className="flex flex-col gap-y-[40px] w-full">
                                    <p className="flex gap-x-[5px] text-tr-l font-medium text-[#369F48]">Навыки в цели 
                                        <p className="text-tr-l font-medium text-orange">
                                            "{editActive.active.name}"
                                        </p>
                                    </p>
                                    <div className="flex flex-col w-full gap-y-[10px]">
                                        {activeSkillList !== undefined && (
                                            <>
                                                {activeSkillList?.map((skill) => {
                                                    return (
                                                        <ThreatCard 
                                                            threat={skill}
                                                            setEditMaterial={setEditMaterial}
                                                            setEditThreat={setEditThreat}
                                                            setOpenedThreat={setOpenedThreat}
                                                            editThreat={editThreat}
                                                            openedThreat={openedThreat}
                                                        />
                                                    );
                                                })}
                                            </>
                                        )}  
                                    </div>
                                </div>
                                
                            </div>
                        ):(
                            <div className="flex h-full w-full">
                                <Loader text="Загружаю"/>
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </main>
    );
};

export default Timeline;
