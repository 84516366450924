import { queryClient } from "queryClient";
import * as http from "shared/http";

export function commitProgress(
    session: number | string,
    percent_progress: number
) {
    percent_progress = Math.max(Math.min(percent_progress, 100), 0);
    http.request({
        method: "put",
        url: `/api/v1/user/content/${session}/progress/commit`,
        data: { percent_progress },
    }).then(() => {
        queryClient.invalidateQueries({
            queryKey: ["MaterialSessionPopup--active--materials"],
        });
    });
}
