import * as http from "shared/http";
import { Paginated, MaterialType } from "shared/types";
import useSWR from "swr";

export function getMaterialsSkill(url: string) {
    return http.request<Paginated<MaterialType>>({
        method: "get",
        url: url,
    });
}

export default function useMaterialsSkill(skillId) {
    const { data, error } = useSWR(
        `/api/v1/user/active_threat/${skillId}/content`,
        getMaterialsSkill
    );

    return {
        materialsList: data,
        isLoadingMaterial: !error && !data,
        isError: error,
    };
}
