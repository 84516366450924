import { useParams, useNavigate } from "react-router-dom";
import useSession from "shared/api/useSession";
import { ReactComponent as Cross } from "shared/ui/icon/CrossIcon.svg";
import ContentAdapter from "./ContentAdapter";
import { PopupTest } from "components/PopupTest/PopupTest";
import { useState } from "react";

function SessionSkeleton() {
    return (
        <main className="flex grow flex-col bg-white p-[25px]">
            <div className="flex justify-between">
                <div className="skeleton-animated h-14 w-[400px]" />
                <div className="skeleton-animated h-14 w-14 !rounded-full" />
            </div>
            <div className="skeleton-animated mt-[25px] grow" />
        </main>
    );
}

function Session() {
    const { id } = useParams();
    const navigate = useNavigate();
    const { data: material, isLoading, isError, mutate } = useSession(id);
    const [openPopupTest, setOpenPopupTest] = useState(false);

    function handleProgressButton() {
        navigate(-1);
    }

    if (isLoading) return <SessionSkeleton />;
    if (isError) window.location.replace("/error/400");

    return (
        <>
            <section className="fixed inset-0 z-10 !mx-0 grow">
                <div
                    onClick={handleProgressButton}
                    className="fixed inset-0 bg-blue bg-opacity-30 transition-opacity"
                ></div>
                <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform 
                    w-full max-w-[1800px] h-full max-h-[900px]"
                >
                    <div className="flex w-full h-full relative rounded-xl bg-white shadow-lg">
                        {material != undefined && (
                            <section className="relative flex h-full w-full grow flex-col rounded-xl bg-white pb-1">
                                <div className="relative z-50 rounded-t-xl bg-white border-b-[2px] border-[#566DA3] items-center p-[20px] text-[#264354]">
                                    <div className="flex flex-col gap-[5px]">
                                        <p className="text-[16px] font-medium leading-[19.2px] text-[#566DA3] ">
                                            Просмотр {material.name}
                                        </p>
                                    </div>
                                    <div
                                        className="group absolute right-[20px] top-[10px] cursor-pointer p-2"
                                        onClick={handleProgressButton}
                                    >
                                        <Cross className="fill-black stroke-[#566DA3]" />
                                    </div>
                                </div>
                                <div className="px-[20px] w-full flex h-full grow overflow-y-auto">
                                    {material === null ? (
                                        <div></div>
                                    ) : (
                                        <ContentAdapter
                                            session={id}
                                            material={material}
                                            mutate={mutate}
                                            openPopupTest={openPopupTest}
                                            setOpenPopupTest={setOpenPopupTest}
                                        />
                                    )}
                                </div>
                            </section>
                        )}
                    </div>
                </div>
            </section>
            {!openPopupTest ? (
                ""
            ) : (
                <PopupTest
                    session={id}
                    quiz={material.quiz}
                    setOpenPopupTest={setOpenPopupTest}
                />
            )}
        </>
    );
}

export default Session;
