import { useStore } from "effector-react";
import { getUser } from "shared/api/useUser";
import * as api from "shared/http";
import HumanLogin from "shared/image/greetingPic3.png";
import AdminIcon from "shared/image/login-admin-icon.png";
import LogInForm from "shared/ui/forms/LoginForm";
import useSWR from "swr";

function Authorization() {
    const tokenState = useStore(api.$tokenState);

    const { data: user, error } = useSWR(
        tokenState === "is_admin" ? "/api/v1/profile" : null,
        getUser
    );
    const isLoading = !error && !user;

    function handleAdminClick(e: React.SyntheticEvent) {
        e.stopPropagation();
        localStorage.setItem("force_user", "false");
        api.assignLocationAsync("/admin");
    }

    function handleUserClick(e: React.SyntheticEvent) {
        e.stopPropagation();
        localStorage.setItem("force_user", "true");
        api.changeTokenState("valid");
    }

    function handleClose() {
        localStorage.clear();
        api.changeTokenState("invalid");
    }

    const creds = isLoading ? (
        <>
            <span className="skeleton-animated mb-[2px] mt-[47px] h-5 w-48"></span>
            <span className="skeleton-animated my-[2px] h-5 w-64"></span>
        </>
    ) : (
        <>
            <span className="mt-[45px] text-tr-l font-bold">
                {user.surname}
            </span>
            <span className="text-tr-l">
                {user.first_name} {user.last_name}
            </span>
        </>
    );

    return (
        <section
            onClick={handleClose}
            className="relative flex h-screen w-full items-center justify-center overflow-hidden"
        >
            <div className="flex h-full w-full max-w-[1415px] justify-between px-[40px] pt-[30px] max-[1124px]:justify-center max-[1124px]:pt-[25px]">
                <LogInForm />
                <img
                    src={HumanLogin}
                    alt="greeting"
                    className="absolute right-0 top-0 h-full max-[1124px]:hidden"
                />
                {tokenState === "is_admin" && (
                    <div className="absolute left-0 top-0 z-50 flex h-screen w-screen items-center justify-center space-x-[25px] backdrop-blur-sm">
                        <div
                            onClick={handleAdminClick}
                            className="group cursor-pointer select-none rounded-[40px] bg-white p-[30px] shadow-darkBlue hover:bg-main-dark"
                        >
                            <div className="mx-[68.5px] mb-[20px] mt-[30px] flex flex-col items-center group-hover:text-white">
                                <span className="text-tr-xl">Войти как</span>
                                <span className="text-tr-xl font-bold">
                                    Администратор
                                </span>
                                <img
                                    src={AdminIcon}
                                    alt="admin icon"
                                    className="mt-[45px] h-[160px] w-[160px] rounded-full"
                                />
                                {creds}
                            </div>
                            <svg
                                width="55"
                                height="55"
                                viewBox="0 0 55 55"
                                xmlns="http://www.w3.org/2000/svg"
                                className="ml-auto mt-[50px] h-[55px] w-[55px] rounded-full bg-main-dark fill-white group-hover:bg-white group-hover:fill-main-dark"
                            >
                                <path d="M38.3819 33.7822L32.6084 23.7822L26.8349 33.7822L38.3819 33.7822ZM15.3652 21.6483L30.0877 30.1483L31.0877 28.4162L16.3652 19.9162L15.3652 21.6483Z" />
                            </svg>
                        </div>

                        <div
                            onClick={handleUserClick}
                            className="group cursor-pointer select-none rounded-[40px] bg-white p-[30px] shadow-darkBlue hover:bg-main-dark"
                        >
                            <div className="mx-[68.5px] mb-[20px] mt-[30px] flex flex-col items-center group-hover:text-white">
                                <span className="text-tr-xl">Войти как</span>
                                <span className="text-tr-xl font-bold">
                                    Пользователь
                                </span>
                                <img
                                    src={AdminIcon}
                                    alt="user icon"
                                    className="mt-[45px] h-[160px] w-[160px] rounded-full"
                                />
                                {creds}
                            </div>
                            <svg
                                width="55"
                                height="55"
                                viewBox="0 0 55 55"
                                xmlns="http://www.w3.org/2000/svg"
                                className="ml-auto mt-[50px] h-[55px] w-[55px] rounded-full bg-main-dark fill-white group-hover:bg-white group-hover:fill-main-dark"
                            >
                                <path d="M38.3819 33.7822L32.6084 23.7822L26.8349 33.7822L38.3819 33.7822ZM15.3652 21.6483L30.0877 30.1483L31.0877 28.4162L16.3652 19.9162L15.3652 21.6483Z" />
                            </svg>
                        </div>
                    </div>
                )}
            </div>
        </section>
    );
}

export default Authorization;
