import * as http from "shared/http";
import * as types from "shared/types";
import useSWR from "swr";

export function getIncomplete(url: string): Promise<types.Incomplete> {
    return http.request<types.Incomplete>({
        method: "get",
        url: url,
    });
}

export function useIncomplete() {
    const { data, error } = useSWR(`/api/v1/user/content`, getIncomplete);

    return { data: data, isLoading: !error && !data, isError: error };
}
