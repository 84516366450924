import * as http from "shared/http";
import { Paginated, ActiveThreatType } from "shared/types";
import useSWR from "swr";

export function getLeftSkillsGoal(url: string) {
    return http.request<Paginated<ActiveThreatType>>({
        method: "get",
        url: url,
    });
}

export default function useLeftSkillsGoal(id) {
    const { data, error } = useSWR(
        `/api/v1/user/trajectory_active/${id}/threats`,
        getLeftSkillsGoal
    );

    return {
        leftSkillList: data,
        isLoading: !error && !data,
        isError: error,
    };
}
