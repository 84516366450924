import { color } from "echarts";
import React from "react";

type Params = {
    width?: number;
    progress?: number;
    className?: string;
    colorLine?: string;
    colorText?: string;
    ringType?: string;
};

function ProgressCircle({
    width = 15,
    progress = 0.65,
    className = "",
    colorLine,
    colorText,
    ringType
}: Params) {
    const radius = 100;

    const itog = Number(progress.toPrecision(1));

    return (
        <div className="relative">
            <p className={`absolute text-${colorText} 
                ${ringType === "big" && "flex w-full h-full items-center justify-center text-tr-xl font-bold"} 
                ${ringType === "middle" && "flex w-full h-full items-center justify-center text-tr-l font-bold"}
                ${ringType === "short" && "flex w-full h-full items-center justify-center text-tr-m font-bold"}
                `}
            >
                {itog * 100} %
            </p>
            <svg
                className={className}
                viewBox={`0 0 ${radius * 2.2} ${radius * 2.2}`}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle
                    className="text-slightblue"
                    strokeWidth={width}
                    stroke="#2643541A"
                    fill="transparent"
                    r={radius}
                    cx="50%"
                    cy="50%"
                />
                <circle
                    className="text-blue"
                    strokeWidth={width}
                    strokeDasharray={2 * Math.PI * radius}
                    strokeDashoffset={
                        2 * Math.PI * radius - 2 * Math.PI * radius * progress
                    }
                    strokeLinecap="round"
                    stroke={colorLine}
                    fill="transparent"
                    transform={`rotate(-90 ${radius * 1.1} ${radius * 1.1})`}
                    r={radius}
                    cx="50%"
                    cy="50%"
                />
            </svg>
        </div>
        
    );
}

export default ProgressCircle;
