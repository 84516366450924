import * as api from "shared/http";
import * as types from "shared/types";

import * as toasts from "shared/ui/customToasts";

const hostname = window.location.hostname;
export let notificationsSocket: WebSocket = null;

api.$tokenState.watch(function (state) {
    if (state === "valid" && !notificationsSocket) {
        notificationsSocket = new WebSocket(
            `ws://${hostname}/ws/notifications?token=${api.access_token}`
        );

        notificationsSocket.onopen = function (event) {
            console.log("[onopen]", event);

            // toasts.infoToast("Уведомления подключены");
        };

        notificationsSocket.onmessage = function (event) {
            console.log(`[onmessage] ${event.data}`);
            try {
                const { text: data }: types.WSMessage = JSON.parse(event.data);
                const note_type = data?.notification?.note_type;
                const content = data?.notification?.content;
                if (!note_type) throw new Error("Invalid message");

                toasts.infoToast(content);
            } catch (error) {
                console.error("notificationsSocket catch:", error);
            }
        };

        notificationsSocket.onclose = function (event) {
            if (event.wasClean) {
                console.log(
                    `[onclose] code=${event.code} reason=${event.reason}`
                );
            } else {
                console.log("[onclose] Connection died");
            }
            notificationsSocket = null;
        };

        notificationsSocket.onerror = function (error) {
            console.log(`[error]`, error);
        };
    }
});
