

export const ArrowComponent = () => {
    return (
        <div className="flex min-h-[50px] h-full grow">
            <div className="flex h-full flex-col items-center pb-[5px]">
                {/* <div className="flex h-[10px] w-[10px] items-center justify-center rounded-full border bg-[#F37D7380] text-[white]"></div> */}
                <div className="h-full w-[1px] border-dashed border-[1px] border-main-dark"></div>
                <div className="h-0 w-0 border-x-[7px] border-t-[7px] border-solid border-x-transparent border-main-dark"></div>
            </div>
        </div>
    );
};
