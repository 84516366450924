import { truncateText } from "utils/truncateText";
import * as types from "shared/types";
import { useSessionPopup } from "components/MaterialSessionPopup/model";

interface Props {
    material: types.MaterialType;
    setEditMaterial: React.Dispatch<React.SetStateAction<types.MaterialType | undefined>>;
    threatId: number;
}

export const MaterialCard: React.FC<Props> = ({
    material,
    setEditMaterial,
    threatId
}) => {

    const openActiveMaterial = useSessionPopup((state) => state.open);

    const handleClickLinkActive = (threatId: number, materialId: number) => {
        if (typeof threatId !== "number") {
            throw new Error("invalid activeId");
        }
        openActiveMaterial(null, threatId, materialId);
    };

    function openCard(evt: React.MouseEvent<HTMLDivElement>){
        evt.stopPropagation();
        setEditMaterial(material);
    }

    return (
        <div 
            className="relative flex flex-col max-w-[775px] h-[165px] p-[20px] justify-between shadow-[0_0_10px_0_rgba(38,67,84,0.1)] rounded"
            onClick={openCard}
        >
            <div className="flex w-full justify-between">
                <div className="flex gap-x-[10px] items-center">
                    <div className="text-[14px] font-bold leading-[16.8px] text-[#566DA3]">{material.content_type.verbose_name}</div>
                </div>
            </div>
            <p>
                {truncateText(material.name, 2)}
            </p>
            <div className="flex w-full justify-end pt-[15px] items-end">
                {/* <div className="flex flex-col gap-y-[10px]">
                    <p className="text-tr-s text-main-dark font-medium">Время изучения</p>
                    <div className="flex items-center justify-center h-[25px] w-full max-w-[145px] bg-[#D6D6D6] text-tr-s font-normal text-[#707070] rounded">
                        2 часа 15 минут
                    </div>
                </div> */}
                <div 
                onClick={() => handleClickLinkActive(threatId, material.id)}
                className="flex gap-x-[10px] text-main-dark items-center justify-center rounded-full h-[35px] w-full max-w-[185px] shadow-[0_0_10px_0_rgba(38,67,84,0.1)]"
                >
                    <p className="text-tr-s font-medium">
                        Изучить 
                    </p>
                    &#10230;
                </div>
            </div>
        </div>    
    );
};
