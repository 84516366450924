import { truncateText } from "utils/truncateText";
import { useEffect } from "react";
import { useState } from "react";
import { useStore } from "effector-react";
import {
    CreateModelApi,
    CreatePaginatedModel,
} from "shared/paginatedModelBuilder";
import * as types from "shared/types";
import { LoaderConstructor } from "./LoaderConstructor/LoaderConstructor";
import { MaterialCard } from "./MaterialCard";
import ProgressCircle from "shared/ui/ProgressCircle";
import { useSessionPopup } from "components/MaterialSessionPopup/model";
import { ReactComponent as TestArrow } from "../../shared/ui/icon/TestArrow.svg";
import { ArrowComponent } from "./ArrowComponent/ArrowComponent";

interface Props {
    threat: types.ActiveThreatType | undefined;
    setEditThreat: React.Dispatch<React.SetStateAction<types.ActiveThreatType | undefined>>;
    editThreat: types.ActiveThreatType | undefined;
    setOpenedThreat: React.Dispatch<React.SetStateAction<string>>; 
    openedThreat: string;
    setEditMaterial: React.Dispatch<React.SetStateAction<types.MaterialType | undefined>>; 
}

const SkillsContentModel = CreatePaginatedModel<types.MaterialType>("SkillsContent");

export const ThreatCard: React.FC<Props> = ({
    threat,
    setEditThreat,
    editThreat,
    setOpenedThreat,
    openedThreat,
    setEditMaterial
}) => {
    const content = useStore(SkillsContentModel.store); // Получение модели умов навыка
    const contentList = content.results; // Получение результатов модели
    const [isLoadingContent, setIsLoadingContent] = useState(false);
    const [openMaterials, setOpenMaterials] = useState(false);

    function openThreat(){
        if(openMaterials === false){
            setEditThreat(threat);
            setOpenMaterials(true);
            setIsLoadingContent(true);
            if(threat !== undefined)
            setOpenedThreat(threat.threat.name)
        } else {
            setEditThreat(threat);
            setOpenMaterials(false);
            setIsLoadingContent(false);
        }
        
    }

    const openActiveMaterial = useSessionPopup((state) => state.open);

    const handleClickLinkThreat = (threatId: number) => {
        if (typeof threatId !== "number") {
            throw new Error("invalid threatId");
        }
        openActiveMaterial(null, threatId, null);
    };

    function closeThreat(){
        setEditThreat(threat);
        setOpenMaterials(false);
        setIsLoadingContent(false);
    }

    const ModelApi3 = CreateModelApi(
        SkillsContentModel,
        `/api/v1/user/active_threat/${editThreat?.id}/content`,
        "",
        150
    ); 

    useEffect(() => {
        if (isLoadingContent) {
            ModelApi3.loadFirstPage().then(() => {
                setIsLoadingContent(false);
            });
        }
        if (openedThreat !== threat.threat.name) {
            setOpenMaterials(false);
            console.log(openedThreat)
        }
    }, [threat, openMaterials, contentList, openedThreat]);

    return (
        <div 
            className="realtive flex shadow-[0_0_10px_0_rgba(38,67,84,0.1)] p-[20px] min-h-[170px] w-full max-w-[930px] cursor-pointer rounded"
            onClick={openThreat}
        >   
            <div className="flex flex-col items-center">
                <ProgressCircle 
                    className="w-[80px] h-[80px]" 
                    colorLine="#369F48" 
                    colorText="#369F48" 
                    ringType="short"
                    progress={(threat?.completed_content_count / threat?.content_count)}
                />
                {/* <div className="relative flex h-full border-dashed border-[1px] border-main-dark ">
                    <div className="absolute text-main-dark text-[20px] font-black bottom-[-11px] right-[-6px]">
                        &#709;
                    </div>
                </div> */}
                
                {/* <div className="relative flex h-full  ">
                    <TestArrow className="w-[25px]"/>
                </div> */}
                <ArrowComponent />
            </div>
            <div className="flex flex-col w-full justify-between ml-[20px]">
                <div className="flex w-full justify-between pt-[15px]">
                    <p className="text-[16px] font-medium leading-[19.2px]">
                        {truncateText(threat?.threat?.name, 2)}
                    </p>
                    {/* <div className="flex flex-col gap-y-[10px]">
                        <p className="text-tr-s text-main-dark font-medium">Время прохождения</p>
                        <div className="flex items-center justify-center h-[25px] w-full max-w-[145px] bg-[#F37D731A] text-tr-s font-normal text-[#F37D73] rounded">
                            2 часа 15 минут
                        </div>
                    </div> */}
                </div>
                {openMaterials === false ? (
                    <div onClick={(e) => e.stopPropagation()} className="flex w-full justify-between">
                        <div className="flex gap-x-[10px] items-end" onClick={openThreat}>
                            <p className="text-[14px] leading-[16.8px] font-medium text-[#264354] underline">
                                Чтобы освоить навык, необходимо изучить 
                            </p>
                            <div className="flex min-w-[80px] h-[25px] bg-[#26435433] rounded justify-center items-center gap-x-[10px] px-[20px]">
                                <p className="text-[#264354] text-[12px] font-normal leading-[14.4px]">
                                    {threat.content_count || 0} материалов
                                </p>
                                &#8595;
                            </div>
                        </div>
                        <div 
                            onClick={() => (handleClickLinkThreat(threat.id))}
                            className="flex gap-x-[10px] text-main-dark items-center justify-center rounded-full h-[35px] w-full max-w-[185px] shadow-[0_0_10px_0_rgba(38,67,84,0.1)]"
                        >
                            <p className="text-tr-s font-medium">
                                Изучить сразу 
                            </p>
                            &#10230;
                        </div>
                    </div>
                    
                ):(
                    <>
                        {!isLoadingContent ? (
                            <>
                                {contentList.length > 0 ? (
                                    <div className="flex flex-col mt-[45px] relative">
                                        <div className="flex max-w-[775px] items-end justify-between">
                                            <div className="flex gap-x-[10px] items-end">
                                                <p className="text-[14px] leading-[16.8px] font-medium text-[#264354]">
                                                    Чтобы освоить навык, необходимо изучить
                                                </p>
                                                <div className="flex min-w-[80px] h-[25px] bg-[#26435433] rounded justify-center items-center gap-x-[10px] px-[20px]">
                                                    <p className="text-[#264354] text-[12px] font-normal leading-[14.4px]">
                                                        {threat.content_count || 0} материалов
                                                    </p>
                                                    &#8595;
                                                </div>
                                            </div>
                                        </div>
                                        <div className="relative flex flex-col gap-[20px] mt-[30px]">
                                            {contentList.map(
                                                (material) => (
                                                    <MaterialCard 
                                                        material={material}
                                                        setEditMaterial={setEditMaterial}
                                                        threatId={threat.id}
                                                    />
                                                )
                                            )}
                                        </div>
                                    </div>
                                ) : (
                                    <div className="flex h-full w-full flex-col justify-center">
                                        <div className="flex flex-col items-center gap-y-[20px]">
                                            <p className="mb-[20px] text-center text-[16px] font-semibold leading-[19.2px] text-[#F37D73]">
                                                Упс, кажется тут пусто.{" "}
                                                <br />
                                            </p>
                                        </div>
                                    </div>
                                )}
                            </>
                        ) : (
                            <div className="flex h-[65px] w-full justify-center items-center">
                                <LoaderConstructor />
                            </div> // Лоадер
                        )}
                    </>
                )}
            </div>
        </div>
    );
};
