import { Store, Event, createEvent, restore } from "effector";
import { toast } from "react-hot-toast";
import * as http from "shared/http";
import { Paginated } from "./types";

interface BaseT {
    id: number;
}

export interface PaginatedModel<BT extends BaseT, T = Paginated<BT>> {
    store: Store<T>;

    set: Event<Paginated<BT>>;
    reset: Event<void>;

    appendElem: Event<BT>;
    appendList: Event<BT[]>;
    appendPage: Event<Paginated<BT>>;

    remove: Event<number | string>;
}

const defaultValue = {
    count: 0,
    next: null,
    previous: null,
    results: [],
};

export function CreatePaginatedModel<BT extends BaseT>(
    baseUrl = ""
): PaginatedModel<BT> {
    const set = createEvent<Paginated<BT>>("create_" + baseUrl);
    const reset = createEvent("reset_" + baseUrl);

    const appendElem = createEvent<BT>("appendElem_" + baseUrl);
    const appendList = createEvent<BT[]>("appendList_" + baseUrl);
    const appendPage = createEvent<Paginated<BT>>("appendPage_" + baseUrl);

    const remove = createEvent<number | string>("remove_" + baseUrl);

    const store = restore(set, defaultValue)
        .on(appendElem, (state, payload) => ({
            ...state,
            count: state.count + 1,
            results: [...state.results, payload],
        }))
        .on(appendList, (state, payload) => ({
            ...state,
            count: state.count + payload.length,
            results: [...state.results, ...payload],
        }))
        .on(appendPage, (state, payload) => ({
            count: state.count + payload.count,
            next: payload.next,
            previous: state.previous,
            results: [...state.results, ...payload.results],
        }))
        .on(remove, (state, id) => ({
            ...state,
            results: state.results.filter((d) => d.id !== id),
        }))
        .reset(reset);

    return {
        store,
        set,
        reset,
        appendElem,
        appendList,
        appendPage,
        remove,
    };
}

interface Api<BT extends BaseT> {
    loadFirstPage: () => Promise<void>;
    loadPrevPage: (state: Paginated<BT>) => Promise<void>;
    loadNextPage: (state: Paginated<BT>) => Promise<void>;
    appendNextPage: (state: Paginated<BT>) => Promise<void>;
    deleteElement: (id: number | string) => Promise<void>;
    addElement: (elemData: any) => Promise<void>;
}

export function CreateModelApi<BT extends BaseT>(
    model: PaginatedModel<BT>,
    baseUrl: string,
    successAddText = "Элемент добавлен!",
    pageSize = 10
): Api<BT> {
    const page_size = pageSize !== 0 ? `?page_size=${pageSize}` : "";

    const loadFirstPage = async () => {
        const data = await http.request<Paginated<BT>>({
            method: "get",
            url: baseUrl + page_size,
        });

        model.set(data);
    };

    const loadPrevPage = async (state: Paginated<BT>) => {
        const url = state.previous;
        if (!url) return;

        const data = await http.request<Paginated<BT>>({ method: "get", url });
        model.set(data);
    };

    const loadNextPage = async (state: Paginated<BT>) => {
        const url = state.next;
        if (!url) return;

        const data = await http.request<Paginated<BT>>({ method: "get", url });
        model.set(data);
    };

    const appendNextPage = async (state: Paginated<BT>) => {
        const url = state.next;
        if (!url) return;

        const data = await http.request<Paginated<BT>>({ method: "get", url });
        model.appendPage(data);
    };

    const deleteElement = async (id: number | string) => {
        if (id === undefined) return;

        await http.request({
            method: "delete",
            url: `${baseUrl}/${id}`,
        });

        model.remove(id);
    };

    const addElement = async (elemData: any) => {
        const data = await http.request<BT>({
            method: "post",
            url: baseUrl,
            data: elemData,
        });

        model.appendElem(data);
        toast.success(successAddText);
    };

    return {
        loadFirstPage,
        loadPrevPage,
        loadNextPage,
        appendNextPage,
        deleteElement,
        addElement,
    };
}
